import React, { useState, useEffect } from "react";
// import ReccLinks from "../components/reccomendedlinks"
// import Header from "../components/header"
// import Layout from "../components/layout"
import { Helmet } from "react-helmet-async";
import { useQuery } from "@apollo/client";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import { HashLink as Link } from 'react-router-hash-link';

function BlogTemplate(props) {
  const [value, setvalue] = useState("");
  const [valuetwo, setvaluetwo] = useState("");
  let { articleID } = useParams();

  useEffect(()=> {
      import(`./markdown-pages/${articleID}`)
          .then(res => {
              fetch(res.default)
                  .then(res=>res.text())
                  .then(res=>setvaluetwo(res))
				  .catch(err=>console.log("errror " + err))
          })
  },[])
  console.log("blogtemplate " + articleID);

  const title = valuetwo ? valuetwo.TITLE : "Loading";
  const html = "test html";
  const date = valuetwo.date;

  return (
    <div>
      <Helmet>
        <title>My Article</title>
        <meta name={"description"} content={html} />
      </Helmet>

      <div className={"container"}>
        <div className="blog-post-container">
          <div style={{ margin: "20pt auto", fontSize: "30pt" }}>
            <Link style={{ textDecoration: "none" }} to={"/#postlist"}>
              Back
            </Link>
          </div>
          <div className="blog-post">
            <h1>{title}</h1>
            <h2 style={{ fontSize: "15pt" }}>{date}</h2>
            <div
              className="blog-post-content"
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
                paddingBottom: "100px",
                fontSize: "15pt",
              }}
            />
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={valuetwo} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogTemplate;
