import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from "react-router-dom"



export const GET_ISSUES = gql `{
    scanbrands(uniqueID:"abcde") {
    uniqueID
    brand
    model
    link
    price
  }
}`;





function Scooterbrands() {
  const { data, loading } = useQuery(GET_ISSUES, {
    variables: { language: 'english' },
  });
  // console.log("props " + JSON.stringify(data))
  if (loading) {return <p>Loading ...</p>}
  if (data) {
  return (<ul id={"scooterbrandslist"}> {data.scanbrands &&
			data.scanbrands.map((item)=>(
              		<li key={item.uniqueID}>
                    <div state={{ 'scooterbrand': item.uniqueID }} >
                               {item.brand} - {item.model}
                    </div>
					  </li>
			))}</ul>);
} else {
  return (<div>Loading</div>)}
}


export default Scooterbrands
