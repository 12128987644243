import React from "react"
import { Link }  from "react-router-dom"
import Markdown from "react-markdown"
import PostListJson from './markdown-pages/postlist.js'


const PostList = () => {
    const excerptList = PostListJson.map(post => {
        return post.title
    })
    return (
        <div className="postlist" id={"postlist"}>
            <h1 className="title">All Posts</h1>
            {PostListJson.length &&
                PostListJson.map((post, i) => {
                    return (
                        <div key={i} className="post-card">
                            <h2><Link className="links" to={`/post/${post.filename}`}>{post.title}</Link></h2>
                            <small>Published on {post.date}</small>
                            <hr/>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default PostList
