import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {Link} from "react-router-dom";


export const GET_ISSUES = gql `{
    scankeyissue(uniqueID:"abcde") {
    uniqueID
    description
    keyissue
  }
}`;

function KeyIssues() {
  const { data, loading } = useQuery(GET_ISSUES, {
    variables: { language: 'english' },
  });
  // console.log("props " + JSON.stringify(data))
  if (loading) {return <p>Loading ...</p>}
  if (data) {
	  var scankeyissue = data.scankeyissue
  return (<ul id={"scooterbrandslist"}>
	  {scankeyissue &&
			scankeyissue.map((item)=>(
              		<li key={item.uniqueID}>
						<b>{item.keyissue}</b>
						<p>{item.description}</p>
					  </li>
		  				))}
  </ul>);
} else {
  return (<div>Loading</div>)}
}


export default KeyIssues;


