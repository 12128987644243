import React from "react";
import { Link } from "react-router-dom";
import UFBarChart from "./underfivehundredbarchart";
import EditorsChoice from "./editorschoice.png";
import Footer from './footer'

const UnderFivehundred = () => (
  <div className={"App"}>
      <div id={'pagetwoheader'}><Link to={'/'} >WaveArray Mobility</Link></div>
    <div className={"container"}>
      <div
        style={{
          width: "100%",
          height: "50%",
          backgroundColor: "lightgoldenrodyellow",
        }}
      >
        <img src={"./assets/wordcloud.svg"} />
      </div>
      <Link to="/#pricetable/">
        <i className="fas fa-arrow-circle-left" style={{ float: "left" }} />
      </Link>
      <h2 id={"reccomendations"}>These are the best scooters under $500</h2>
      <div className={"affiliategrid "}>
        <div className={"myaffimage"}>
          <Link to="/detailpage/">
            <img
              border="0"
              loading="lazy"
              alt=""
              src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B018KTMOPG&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=wavearray8-20"
            />
          </Link>
        </div>
        <div className={"myafftext2"}>
          <a
            target="_blank"
            href="https://www.amazon.com/gp/product/B018KTMOPG/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B018KTMOPG&linkCode=as2&tag=wavearray8-20&linkId=3aa24dc03b1eab699c43f40a0f4ee8f2"
          >
            Glion Dolly Foldable Lightweight Adult Electric Scooter w/ Premium
            Li-Ion Battery
          </a>
        </div>

        <div>
          <a
            id="$iradid"
            href="http://goto.walmart.com/c/1934362/568862/9383?veh=aff&sourceid=imp_000011112222333344&prodsku=46754736&u=https%3A%2F%2Fwww.walmart.com%2Fip%2F46754736"
          >
            <img
              className={"myaffimage"}
              loading="lazy"
              src="https://i5.walmartimages.com/asr/4f46a5d1-9f58-4cae-b501-a471f3422d53_1.c0efd9eedf369d04e2d438a06f1deab6.jpeg?odnHeight=450&odnWidth=450&odnBg=ffffff"
              border="0"
              alt=""
            />
          </a>
        </div>
        <div className={"myafftext7"}>
          <a
            href={
              "http://goto.walmart.com/c/1934362/568862/9383?veh=aff&sourceid=imp_000011112222333344&prodsku=46754736&u=https%3A%2F%2Fwww.walmart.com%2Fip%2F46754736"
            }
          >
            Great Scooter for kids
          </a>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "orange",
          width: "100%",
          height: "5vh",
          marginBottom: "10px",
        }}
      />
      <UFBarChart />
      <Link to="/">Go back to the homepage</Link>
        <Footer/>
    </div>

  </div>
);

export default UnderFivehundred;
