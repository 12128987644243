import React, { useState, useEffect, useRef } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { motion, useMotionValue } from "framer-motion";
import { ArrowBackIosNew } from "@mui/icons-material";

const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;

function Image({ id }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const [myrepeat, setmyrepeat] = useState(true);
  const [count, setcount] = useState(0);
  const x = useMotionValue(0);
  const mypicturearray = [
    "scooter1.jpg",
    "scooter2.jpg",
    "scooter3.jpg",
    "segway.jpeg",
  ];
  var refimage = useRef("refimage");

  useEffect(() => {
    if (count < 1) {
      changepic(0);

    } else if (count == 1) {
      changepic(1);

    } else if (count == 2) {
      changepic(2);

    } else {
      changepic(3);
    }
  }, [count]);

  function changepic(pic) {

      setIsLoaded(false);
      setIsInView(false);

      setTimeout(() => {
        setIsLoaded(true);
        setIsInView(true);
        refimage.current.src = mypicturearray[pic];
      }, [1500]);

      setmyrepeat(false);

      setTimeout(() => {
        setmyrepeat(true);
      }, [1500]);
  }

  return (
    <section>
      <motion.div
        initial={false}
        animate={
          isLoaded && isInView
            ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
            : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
        }
        transition={{ duration: 0.75, delay: 0.25 }}
        // viewport={{ once: true }}
        onViewportEnter={() => setIsInView(true)}
        id={'motiondivshutters'}

      >
        <img
          ref={refimage}
          src={mypicturearray[0]}
          alt=""
          onLoad={() => {
            setIsLoaded(true);
          }}
          id={'galleryimg'}

        />
        {myrepeat && (
          <motion.div
            className="box"
            animate={{
              scale: [0.3, 0.7, 0.3],
              rotate: [0, 180, 0],
              borderRadius: ["50%", "0%", "50%"],
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              times: [1, 0.7, 0.7, 0.7, 1.3],
              repeat: 1,
              repeatDelay: 1,
            }}
            onClick={() => changepic(count)}
          ></motion.div>
        )}
        {myrepeat && (
          <motion.div
            className="boxback"
            animate={{
              scale: [0.3, 0.7, 0.3],
              rotate: [0, 180, 0],
              borderRadius: ["50%", "0%", "50%"],
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              times: [1, 0.7, 0.7, 0.7, 1.3],
              repeat: 1,
              repeatDelay: 1,
            }}
            onClick={() => changepic(count)}
          ></motion.div>
        )}
        <span
          style={{ position: "absolute", top: "242px", left: "89px" }}
          onClick={() => {
            if (count > 0) {
              setcount(count - 1);
              // changepic(count);
            }

          }}
        >
          <ArrowBackIosNew />
        </span>
        <span
          style={{ position: "absolute", top: "242px", right: "93px" }}
          onClick={() => {
            if (count < 3) {
              setcount(count + 1);
            }

          }}
        >
          <ArrowForwardIosIcon />
        </span>
      </motion.div>
    </section>
  );
}

export default function App() {
  return (
    <>
      <Image id={1} />
    </>
  );
}
