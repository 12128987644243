import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


function Error(err) {
  const [myvar, setmyvar] = useState("");
  console.log("error" + JSON.stringify(err));
  return (
    <div>
      <h2>ERROR</h2>
        <h4>{err}</h4>
      <Link className="quicksetbutton" to={"/"}>
        Home
      </Link>
    </div>
  );
}

export default Error;
