import React from "react";

export default () => (
	<div className={"linklist"}>
          <h2>The e-scooter Industry</h2>
          <h4>There's a lot going on!</h4>
          <h5>Check out these links for great data and trends in the market</h5>
          <hr />
          <p>
            <a
              href={
                "https://mashable.com/article/electric-scooters-nyc-state-passes-bill/"
              }
            >
              E-scooters get thumbs-up to scoot on most NYC streets
            </a>
          </p>
          <p>
            <a
              href={
                "https://pitchbook.com/news/articles/have-we-already-reached-peak-micromobility\n"
              }
            >
              According to a recent Pitchbook article, we may have already
              reached peak e-scooter investment
            </a>
          </p>
          <p>
            <a
              href={
                "https://blog.usejournal.com/how-bird-lime-can-build-moats-17438816abbf"
              }
            >
              How Bird & Lime can build moats
            </a>
          </p>
          <p>
            <a href={"https://ark-invest.com/research/electric-scooters"}>
              Ark Invest analyst Sam Korus also has a piece out highlighting
              less than thrilling unit economics for e-scooters
            </a>
          </p>
          <p>
            <a
              href={
                "https://techcrunch.com/2019/04/17/shared-electric-scooter-rides-accounted-for-45-8-percent-of-all-micromobility-trips-in-2018/\n"
              }
            >
              Shared scooters accounted for 45.8% of micromobility - Techcrunch
            </a>
          </p>
          <p>
            <a
              href={
                "https://blogs.findlaw.com/injured/2019/07/cities-attempt-to-strike-a-balance-on-electric-scooters.html\n"
              }
            >
              Cities strike a balance on E-scooters{" "}
            </a>
          </p>
          <p>
            <a
              href={
                "https://www.expertreviews.co.uk/scooters/1410221/xiaomi-m365-review\n"
              }
            >
              Great review of the Xiaomi M365 scooter
            </a>
          </p>
          <p>
            <a
              href={
                "https://electric-scooter.guide/reviews/segway-ninebot-es2-review/\n"
              }
            >
              Segway Ninebot ES2 review!
            </a>
          </p>
          <p>
            <a
              href={
                "https://www.theverge.com/2019/7/22/20699803/boosted-rev-electric-scooter-injury-broken-finger\n"
              }
            >
              Awesome Boosted Rev Review
            </a>
          </p>
          <p>
            <a
              href={
                "https://www.tomsguide.com/best-picks/razor-electric-scooter-buying-guide"
              }
            >
              Razor USA E-Scooter Reviews
            </a>
          </p>
        <p>
            <a
              href={
                "https://www.nbcbayarea.com/news/business/unicorn-e-scooter-startup-fails-refunds-uncertain/2192561/"
              }
            >
              Unicorn E-Scooter company Fails, Refunds Uncertain
            </a>
          </p>
        <p>
            <a
              href={
                "https://www.businessinsider.com/bird-uber-lyft-lime-e-scooters-compared-lyft-best-2020-1"
              }
            >
              Comparison of Lyft,Bird,Lime and Uber Scooters in Los Angeles - Business Insider
            </a>
          </p>
        </div>
)
