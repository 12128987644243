var postlist = [
  {
    id: 1,
    title: "Airbags",
    date: "December 12, 2019\r",
    filename:
      'airbags.md',
  },
  {
    id: 2,
    title: "Alibaba",
    date: "December 11, 2019\r",
    filename:
      "alibaba.md",
  },
      {
    id: 3,
    title: "Boosted",
    date: "December 11, 2019\r",
    filename:
      "boosted.md",
  },
      {
    id: 4,
    title: "Choosing The Right One",
    date: "December 11, 2019\r",
    filename:
      "choosingtherightone.md",
  },
      {
    id: 5,
    title: "Glion",
    date: "December 11, 2019\r",
    filename:
      "glion.md",
  },
      {
    id: 6,
    title: "Inokim Takes Manhattan",
    date: "December 11, 2019\r",
    filename:
      "inokimtakesmanhattan.md",
  },
      {
    id: 7,
    title: "KickStarter",
    date: "December 11, 2019\r",
    filename:
      "kickstarter.md",
  },
      {
    id: 8,
    title: "Lyft Leaving Markets",
    date: "December 11, 2019\r",
    filename:
      "lyftleavingmarkets.md",
  },
      {
    id: 1575990000,
    title: "New Jersey New York",
    date: "December 11, 2019\r",
    filename:
      "newjerseynewyork.md",
  },
];

export default postlist;
