import React from "react";
import { Link } from "react-router-dom";
import ReccLinks from "./reccomendedlinks";
import ScooterBrands from "./scooterbrands";
import KeyIssues from "./keyissues";
import EditorsChoice from "./editorschoice.png";
import SectionPills from "./SectionPills";
import PostList from "./postlist";
import PriceTable from "./PriceTable";
import Parallax from "./parallax";
import Gallery from './gallerygradient'
// import KeyIssues from "./keyissues"
// import BarChart from "./BarChart"
// import BrandHooks from "./scooterbrands"
// import PriceTable from "./PriceTable"
// import Mycarousel from "./carousel"
// import MyComponent from "./hometab"
// import "./themify-icons.css"
// import MyMaterial from "../components/SectionPills"

export default () => {
  return (
    <div id="container">
      <h1 id={"testvariable"} data-text="Grow">
        Your E-Mobility Center
      </h1>

      <h3>Reviews and Advice to get you Moving</h3>
      <div style={{ backgroundColor: "white" }}>
        <div>
          <a href={"#reccomendations"}>Reccomendations</a>
        </div>

        <div className={"icondiv"}>
          <i className={"ti-bolt mainicons"} />
          <i className={"ti-control-play mainicons"} />
          <i className={"ti-heart mainicons"} />
        </div>
      </div>

      <div
        style={{
          backgroundColor: "orange",
          width: "100%",
          height: "5vh",
          marginBottom: "10px",
        }}
      />
    <Gallery/>
      <div>
        <hr />
        <h2 id={"reccomendations"}>
          These scooters are the best value for your money
        </h2>
        <h4>
          Heads Up: If you like our reccomendations and click through to Amazon
          or Walmart, we may receive a small fee for the referral
        </h4>

          <Link to={"underfivehundred"}>
            <h4>Click Here for Under $500</h4>
          </Link>

        <div className={"affiliategrid "} style={{ padding: "20px" }}>
          <div className={"myaffimage"}>
                <a id="876925504" href="https://goto.walmart.com/c/1934362/1285192/9383?prodsku=876925504&u=https%3A%2F%2Fwww.walmart.com%2Fip%2FGOTRAX-XR-Ultra-Commuting-Electric-Scooter-300W-8-5-Foldable-E-Scooter-for-Adults-and-Child-17mile-Range%2F876925504&intsrc=PUI2_9742" target="_top"><img className='myaffimagewalmartsegway' src="https://i5.walmartimages.com/asr/f5c9a404-4f03-410c-a919-a1a8254961f4.da2b03734f9a26941ae69bc7b49628d4.jpeg?odnHeight=450&odnWidth=450&odnBg=ffffff" border="0" alt=""/></a>
          </div>
          <div className={"myafftext2"}>
            <a
              target="_blank"
              href="https://www.amazon.com/gp/product/B018KTMOPG/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B018KTMOPG&linkCode=as2&tag=wavearray8-20&linkId=3aa24dc03b1eab699c43f40a0f4ee8f2"
            >
              <h3>GOTRAX XR Ultra</h3>
              Looking for a reliable electric scooter for your daily commute?  Provides a smooth and comfortable ride with its powerful 300W motor. Shock absorbers allow you to cruise through bumpy city streets with ease. The GOTRAX XR Ultra is the perfect commuting electric scooter for adults and children alike. Max. Speed：15.5MPH Max. Range：approx 17 miles Tire Size：8.5  Pneumatic Tires Motor Watt：300W Braking System：EABS + Disc Braking Speed Level：Gear1  15.5MPH
            </a>
          </div>

          <div className={"myaffimage"}>
              <a id="674799829" href="https://goto.walmart.com/c/1934362/1285192/9383?prodsku=674799829&u=https%3A%2F%2Fwww.walmart.com%2Fip%2FSegway-Ninebot-KickScooter-E45-Upgraded-Mobility-Dark-Grey%2F674799829&intsrc=PUI2_9742" target="_top"><img className='myaffimagewalmartsegway' src="https://i5.walmartimages.com/asr/4904c136-fc6c-4436-8df4-f5963c3e0935.0af90fc2300e11b495bb04b51dec8cef.png?odnHeight=450&odnWidth=450&odnBg=ffffff" border="0" alt=""/></a>
          </div>
          <div className={"myafftext5"}>
              <a id="674799829" href="https://goto.walmart.com/c/1934362/1285192/9383?prodsku=674799829&u=https%3A%2F%2Fwww.walmart.com%2Fip%2FSegway-Ninebot-KickScooter-E45-Upgraded-Mobility-Dark-Grey%2F674799829&intsrc=PUI2_9742" target="_top">
               <h3>Segway-Ninebot E45</h3>
              <div>This kickscooter inherits the classic design of the Segway-Ninebot Kickscooter series and features integrated molding technology and a metal frame. With the new triple braking system  you get a 3’3’’ braking distance  safeguarding you all the time. It features a 700W powerhouse custom-made  high-performance motor that delivers a top speed of 18.6 mph and can handle a 20% max slope. </div>
              </a>
          </div>

          <div>
            <a
              id="$iradid"
              href="http://goto.walmart.com/c/1934362/568862/9383?veh=aff&sourceid=imp_000011112222333344&prodsku=46754736&u=https%3A%2F%2Fwww.walmart.com%2Fip%2F46754736"
            >
              <img
                className={"myaffimage"}
                src="https://i5.walmartimages.com/asr/4f46a5d1-9f58-4cae-b501-a471f3422d53_1.c0efd9eedf369d04e2d438a06f1deab6.jpeg?odnHeight=450&odnWidth=450&odnBg=ffffff"
                border="0"
                alt=""
              />
            </a>
          </div>
          <div className={"myafftext7"}>
            <a
              href={
                "http://goto.walmart.com/c/1934362/568862/9383?veh=aff&sourceid=imp_000011112222333344&prodsku=46754736&u=https%3A%2F%2Fwww.walmart.com%2Fip%2F46754736"
              }
            >
              <h3>Razor</h3>
              Great Scooter for kids Power Core 90 Electric-Powered Scooter with
              Rear Wheel Drive
            </a>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "orange",
            width: "100%",
            height: "5vh",
            marginBottom: "10px",
          }}
        />
        <SectionPills />
        <div
          style={{
            backgroundColor: "orange",
            width: "100%",
            height: "5vh",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />
        <PostList />
        <div
          style={{
            backgroundColor: "orange",
            width: "100%",
            height: "5vh",
            marginBottom: "10px",
          }}
        />
        <PriceTable />
        <div
          style={{
            backgroundColor: "orange",
            width: "100%",
            height: "auto",
            marginBottom: "10px",
          }}
        >
          <h2>Check out these accessories for your scooter!</h2>
        </div>

        <div id={"accessorygrid"}>
          <a id="298481461" href="https://goto.walmart.com/c/1934362/1285207/9383?prodsku=298481461&u=https%3A%2F%2Fwww.walmart.com%2Fip%2FScooter-Storage-Bag-Electric-Scooter-Front-Hanging-Bag-Scooter-EVA-Bag%2F298481461&intsrc=PUI2_9744" target="_top"><img src="https://i5.walmartimages.com/asr/633213e9-c65c-4a3e-9cfa-5546dd0106b8.1f6a42319439f191d36ae5a38048b334.jpeg?odnHeight=450&odnWidth=450&odnBg=ffffff" border="0" alt=""/></a>
          <a id="541696379" href="https://goto.walmart.com/c/1934362/1285449/9383?prodsku=541696379&u=https%3A%2F%2Fwww.walmart.com%2Fip%2FScooter-Reflective-Sticker-Night-Reflective-Film-Side-Stickers-Decals-Set-For-XiaoMi-1-1S-Pro-Scooter-Accessories%2F541696379&intsrc=PUI2_9779" target="_top"><img src="https://i5.walmartimages.com/asr/78347c3f-2866-4b59-805c-a4f05b21071f.b468b2217365d46539b29a4bc5ac5b9f.jpeg?odnHeight=450&odnWidth=450&odnBg=ffffff" border="0" alt=""/></a>
          <a id="609240499" href="https://goto.walmart.com/c/1934362/1285192/9383?prodsku=609240499&u=https%3A%2F%2Fwww.walmart.com%2Fip%2FSANWOOD-Phone-Holder-Motorcycle-Bike-Electric-Scooter-360-Rotation-Handlebar-Phone-Holder-Bracket%2F609240499&intsrc=PUI2_9742" target="_top"><img src="https://i5.walmartimages.com/asr/61c31df3-ca8b-4b5a-9ae8-7429a35961c0.98a0f4081aff618608bd0f78974a8bee.jpeg?odnHeight=450&odnWidth=450&odnBg=ffffff" border="0" alt=""/></a>
          <a id="1703800269" href="https://goto.walmart.com/c/1934362/1285192/9383?prodsku=1703800269&u=https%3A%2F%2Fwww.walmart.com%2Fip%2FWater-Bottle-Holder-Milk-Bottle-Holder-Cup-Holder-for-Bike-Scooter%2F1703800269&intsrc=PUI2_9742" target="_top"><img src="https://i5.walmartimages.com/asr/88720010-7bd8-4f3f-941e-3378c8bfca30.d0be8df2350e0f68feb3cbba4c137527.jpeg?odnHeight=450&odnWidth=450&odnBg=ffffff" border="0" alt=""/></a>

        </div>
        <div
          style={{
            backgroundColor: "orange",
            width: "100%",
            height: "5vh",
            marginBottom: "10px",
          }}
        />
        <Parallax />
        <div
          style={{
            backgroundColor: "orange",
            width: "100%",
            height: "5vh",
            marginBottom: "10px",
          }}
        />

        <div id={"magazinegrid"}>
          <section>
            <h2>Scooter Retail Brands</h2>
            <ul className="link-list">
              <ScooterBrands />
            </ul>
          </section>

          <section>
            <h2>Scooter Key Issues</h2>
            <KeyIssues />
          </section>
          <section className="middle-content">
            <h2>Scooter Rental Companies</h2>
            <ul>
              <li>
                <a href="https://bird.co">Bird</a>
              </li>
              <li>
                <a href="https://li.me">Lime</a>
              </li>
              <li>
                <a href="https://micromobility.com">Bolt</a>
              </li>
              <li>
                <a href="https://jump.com">Jump</a>
              </li>
              <li>
                <a href={"https://www.lyft.com/scooters"}>Lyft</a>
              </li>
              <li>
                <a href={"https://scoot.co"}>Scoot</a>
              </li>
              <li>
                <a href={"https://spin.app"}>Spin</a>
              </li>
              <li>
                <a href={"https://skipscooters.com"}>Skip</a>
              </li>
              <li>
                <a href={"https://zapprideshare.com"}>Zapp</a>
              </li>
              <li>
                <a href={"https://veoride.com"}>VeoRide</a>
              </li>
              <li>
                <a href={"https://ridegotcha.com"}>Gotcha</a>
              </li>
              <li>
                <a href={"https://gruv.app"}>Gruv</a>
              </li>
              <li>
                <a href={"https://blink-rides.com"}>Blink Rides</a>
              </li>
              <li>
                <a href="https://www.razor.com/share/">Razor USA Share</a>
              </li>
              <li>
                <a href={"https://www.flyblueduck.com/"}>Blue Duck</a>
              </li>
              <li>
                <a href={"https://www.zagster.com/"}>Zagster</a>
              </li>
              <li>
                <a href={"https://ojoelectric.com/"}>Ojo</a>
              </li>
              <li>
                <a href={"https://helbiz.com/go"}>HelBizGo</a>
              </li>
            </ul>
          </section>
        </div>

        <div
          style={{
            backgroundColor: "orange",
            width: "100%",
            height: "5vh",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />
        <ReccLinks />
      </div>
    </div>
  );
};
