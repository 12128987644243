import React, {useEffect, useState} from "react"
// import Layout from "../components/layout"
import './App.scss'
import PageOne from './page-1'
import ScooterBrands from './scooterbrands'
import SectionPills from './SectionPills'
import SEO from './seo'
import Footer from './footer'
// import Carousel from './carousel'
// import HomePage from '../components/HomePage'
// import Icon from "./assets/twitter.svg"
// import Icon2 from "./assets/facebook.svg"
// import Logmyip from '../components/logmyip'
// import { uuid } from 'uuidv4';
// var ip = require('ip');
// var myip = ip.address() // my ip address
// var myid =uuid()



const IndexPage = () => {

    return (

    <div className={'App'} style={{'display': 'flex', 'flexDirection': 'column', 'minHeight': '100vh'}}>
        <SEO title={'WaveArray Mobility'} />

        <header className="App-header">

            <video id={"video2"} style={{width: "100%"}} loop={true} autoPlay muted playsInline type="video/mp4"
                   src="https://res.cloudinary.com/galaxy2312cloud/video/upload/v1571345339/dave/imoviescootvid_mofszi.mp4"/>
        </header>
        <div homepage={'main'}>
            <div id={"overlay"}>
                <h1>Micromobility Scooters Are Green Fast and Fun!</h1>
                <h2>Most than 100 US cities now have dockless rental scooters</h2>
                <h3>Buying a Personal E-Scooter Is Also a Great Option</h3>
            </div>
            <div id={"overlay2"}>
                <ul id="iconslist">
                    <li>
                        <a href="https://twitter.com/ArrayWave">
                            <img src={'/assets/twitter.svg'}/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/WaveArray-Mobility-2533129020054287/">
                            <img src={'/assets/facebook.svg'}/>
                        </a>
                    </li>
                </ul>
                <br/>
            </div>
        </div>
        <PageOne/>
        <Footer/>
    </div>
    )
}

export default IndexPage
