import React from "react"
import EmailSignUp from "./emailsignuphookform";
import Disclaimer from "./disclaimer";


const Footer = ({ siteTitle }) => (
          <div className="bottombox" style={{}}>
            <div className="footer " style={{'textAlign':'center'}}>
              <div  className={"mysubgrid"}>
                <div>
                  <b>Cities</b>
                  <Disclaimer footerid={"New York"} />
                  <Disclaimer footerid={"Los Angeles"} />
                  <Disclaimer footerid={"Chicago"} />
                  <Disclaimer footerid={"San Francisco"} />
                </div>

                <div>
                  <b>Services</b>
                  <Disclaimer footerid={"Free"} />
                  <Disclaimer footerid={"Tier 1"} />
                </div>

                <div>
                  <b>About</b>
                  <Disclaimer footerid={"Mission"} />
                  <Disclaimer footerid={"Story"} />
                </div>

                <div>
                  <b>Help</b>
                  <Disclaimer footerid={"Newsletter Signup"} />
                  <Disclaimer footerid={"Disclaimer"} />
                  <Disclaimer footerid={"Contact"} />
                </div>
              </div>
            </div>
          </div>
	)

export default Footer
