import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./homepage";
import BlogTemplate from "./blogTemplate";
import UnderFive from "./page-2";
import Error from "./Error";

const Router = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/post/:articleID" element={<BlogTemplate />} />
        <Route path="/underfivehundred" element={<UnderFive />} />
        <Route path="/error" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
