/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet-async"
import { gql, useQuery, useMutation } from "@apollo/client";

function SEO({ description, lang, meta, title }) {

  const metaDescription = description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}

      meta={[
            {
              name: `description`,
              content:
                "electric scooters bird lime gotcha ojo spin scoot segway ninebot xiaomi bolt "
            },
            {
              property: `og:title`,
              content: "WaveArray"
            },
            {
              property: `og:description`,
              content:
                "WaveArray Mobility E-scooters, scooters and micro-mobility"
            },
            {
              property: `og:type`,
              content: `website`
            },
            {
              name: `twitter:card`,
              content: `WaveArray Mobility E-scooters, scooters and micro-mobility`
            },
            {
              name: `twitter:creator`,
              content: "WaveArray"
            },
            {
              name: `twitter:title`,
              content: "WaveArray Micromobility"
            },
            {
              name: `twitter:description`,
              content: "WaveArray Mobility"
            }
      ].concat(meta)}
    >

        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
              integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous"/>
        <link rel="preload" href="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B076KKX4BC&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=wavearray8-20" as={'image'} />

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
