import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class Disclaimer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
     modalIsOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

      openModal() {
    this.setState({modalIsOpen: true});
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = 'darkviolet';
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render(footerid) {
    switch(this.props.footerid) {
  case "Disclaimer":
    var mytext = "All the information on this website is published in good faith and for general information purpose only. WaveArray does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (WaveArray.com), is strictly at your own risk. will not be liable for any losses and/or damages in connection with the use of our website."
    break;
  case "Contact":
    var mytext = "You can reach us at: webmaster@wavearray.com"
    break;
  default:
    var mytext = "Coming Soon"
}
    return (
       <div style={{position:'relative'}}>
        <div onClick={this.openModal}>{this.props.footerid}</div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <div ref={subtitle => this.subtitle = subtitle}>
            <h3>{mytext}</h3>
          </div>
          <button onClick={this.closeModal}>close</button>

        </Modal>
      </div>


    );
  }
}

export default Disclaimer;
