import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Dashboard from '@mui/icons-material/AccessAlarm';
// import Schedule from '@mui/icons-material/ThreeDRotation';
// import List from '@mui/icons-material/ThreeDRotation';
import FavoriteIcon from "@mui/icons-material/Favorite";
import { AccessAlarm, ThreeDRotation } from "@mui/icons-material";
import { Grid, Box, Tabs, Tab } from "@mui/material";
// import TabContext from '@mui/material/Tabs'
// import TabList from '@mui/material/Tabs'
// import TabPanel from '@mui/material/Tabs'

function SectionPills() {
  const [value, setvalue] = useState(1);
  const [currentTabIndex, setcurrentTabIndex] = useState(1);
  var handleChange = (elem, number) => {
    console.log("handle change " + elem + number);
    setvalue(number);
    setcurrentTabIndex(number);
  };
  return (
    <div id={"sectionpills"}>
      <div>
        <div id="navigation-pills">
          <Grid container spacing={1}>

            <Box style={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
              <Tabs
                  variant="fullWidth"
                onChange={(elem, number) => handleChange(elem, number)}
                value={value}
                aria-label="lab API tabs example"
              >
                <Tab icon={<FavoriteIcon />} label="Choosing" value={1} />
                <Tab label="Dockless" value={2} />
                <Tab label="Renting" value={3} />
              </Tabs>
            </Box>

            {value == 3 && (
              <div value={value} index={1} className={"tabpanel"}>
                <Grid item xs={12} md={12} lg={12}>
                  <div>
                    <span className={"gridcontainer"}>
                      <p>
                        The scooter industry is experiencing tremendous growth
                        and investment. Over 100 US cities now have sharable
                        scooters.
                      </p>
                      <p>
                        Rental scooters are great if you're traveling or
                        visiting a city.
                      </p>
                      <p>
                        But, what if you don't like the rental scooters and want
                        your own ride, or what if rental scooters aren't
                        available in your hometown like New York City? We're
                        here to help !
                      </p>
                      <br />
                    </span>
                  </div>
                </Grid>
              </div>
            )}

            {value == 2 && (
              <div value={value} index={2} className={"tabpanel"}>
                <Grid item xs={12} md={12} lg={12}>
                  <div>
                    <span className={"gridcontainer"}>
                      <p>
                        Dockless Rental Scooters are great for convenience and
                        usability. Many cities allow them to be left just about
                        anywhere. Unlocking and riding is as easy as opening an
                        app.
                      </p>
                      <p>
                        Using a rental scooter means you don't have to worry
                        about charging it, fixing it or parking it. A great
                        solution for many everyday needs.
                      </p>
                    </span>
                  </div>
                </Grid>
              </div>
            )}
            {value == 4 && (
              <div value={value} index={4} className={"tabpanel"}>
                <Grid item xs={12} md={12} lg={12}>
                  <div>
                    <span className={"gridcontainer"}>
                      <p>
                        New Scooters and Scooter stores are opening up rapidly.
                        Having your own scooter means that you can customize it,
                        not only to your body, but, also to your city. This will
                        definitely help in the speed and durability areas.
                      </p>
                      <p>
                        If you're going to use your scooter a few times a week,
                        pretty soon, it starts adding up. Scooters are
                        dramatically coming down in price, and the cost benefit
                        is heavily tilting toward buying one.
                      </p>
                      <p>
                        Newer Scooters have suspension, disk brakes, safety
                        lighting and more powerful motors.
                      </p>
                      <p>
                        Take a look at our comparison charts and industry
                        break-down to choose the right model for you!
                      </p>
                    </span>
                  </div>
                </Grid>
              </div>
            )}
            {value == 1 && (
              <div value={value} index={0} className={"tabpanel"}>
                <Grid item xs={12} md={12} lg={12}>
                  <div>
                    <span className={"gridcontainer"}>
                      <p>
                        <b>Got Hills?</b> Scooters with a Dual Motor like the
                        Unagi E450 are able to take hills much more efficiently
                        than single-motor models. They won't make you go faster,
                        but they will definitely keep your battery from draining
                        on those inclines.
                      </p>
                      <p>
                        <b>Got Rain?</b> Make sure you get a scooter like the
                        Segway Ninebot ES4 that has a water-resistant seal.
                      </p>
                      <p>
                        <b>Got Potholes?</b> A scooter with Pneumatic tires like
                        the Xiaomi M365 uses air-filled tires to help cushion
                        you on your ride. Solid core plastic tires have been
                        known to send riders flying when they hit a bump in the
                        road
                      </p>
                      <p>
                        <b>Got a Long Commute?</b> Or a commute that requires
                        you to carry your scooter for a while? Make sure you get
                        one of the lighter scooters like the Razor E-90 that
                        only weighs about 24lbs
                      </p>
                      <p>
                        <b>Got tools?</b> Many of these scooters require a
                        little maintenance after riding, especially after long
                        distances. And, some of these scooters require heavy
                        tooling, if, for instance you puncture a tire and need
                        to replace it. A little Do-It-Yourself goes a long way.
                      </p>
                      <p>
                        <b>Got time?</b> Nearly all of these scooters are made
                        in China. There is a range of support that you will
                        receive, depending on the model you've purchased. In
                        many cases, the companies will require you to mail back
                        the scooter to either their US location or abroad. That
                        takes time, and you might be waiting up to 1 month to
                        receive your scooter back.
                      </p>
                    </span>
                  </div>
                </Grid>
              </div>
            )}
            {value == 5 && (
              <div value={value} index={5} className={"tabpanel"}>
                <Grid item xs={12} md={12} lg={12}>
                  <div>
                    <span className={"gridcontainer"}>
                      <p>
                        Electric Powered vehicles produce no emissions, and are
                        much better for the environment.
                      </p>
                      <p>
                        However, many rental scooter comapanies use a network
                        gig-economy 'juicers' that collect rental scooters each
                        night and haul them to charging stations (and then
                        return them)
                      </p>
                      <p>
                        Having your own scooter means charging it at home, and
                        only when you need it. A much 'greener' solution.
                      </p>
                    </span>
                  </div>
                </Grid>
              </div>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default SectionPills;
