import React from "react";
import { Parallax, Background } from "react-parallax";
import { gql, useQuery } from '@apollo/client';


const MyComponent = () => {

  return (  <div className={"myparallax"}>
      <Parallax strength={1000}>
      <Background >
          <img
            id={"mainpic"}
            src={"https://picsum.photos/800/1000"}
          />
      </Background>
      </Parallax>
  </div>
  )
}


export default MyComponent;
