import React from "react";
import Modal from "react-modal";

// import HooksForm from './hooksform'

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    overflow:"hidden",

  }
};

class EmailSignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
      modalIsOpen: false,
      validated: false
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {



  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = "darkviolet";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }



  render(footerid) {

    return (
      <div>
        <div onClick={this.openModal}>{this.props.footerid}</div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
          id={'mymodal'}
        >
          <div  ref={subtitle => (this.subtitle = subtitle)}>

          </div>
          <button onClick={this.closeModal}>close</button>
        </Modal>
      </div>
    );
  }
}

export default EmailSignUp;
