import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Router from "./router";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";

const client = new ApolloClient({
  uri: "https://5w39s4o3wd.execute-api.us-east-2.amazonaws.com/dev/testcors",
  fetchOptions: {
    mode: "no-cors",
  },
  headers: {
    "X-Api-Key": "da2-gu75dggzznhexky7n7ikscw5ha",
  },
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <HelmetProvider>
                <Helmet>
        <title>WaveArray MicroMobility Site</title>
        <link rel="canonical" href="https://www.wavearray.com/" />
      </Helmet>
        <Router />
      </HelmetProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
