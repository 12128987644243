import React from "react"
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom"

export const GET_ISSUES = gql`
  {
    scanbrands(uniqueID: "abcde") {
      uniqueID
      brand
      model
      link
      price
    }
  }
`


export default () => {
  const { data } = useQuery(GET_ISSUES, {
    variables: { language: "english" },
  })
  // console.log("data " + JSON.stringify(data));

  const underfivehundred = []

  const brandsifdata = data ? data.scanbrands : null

  // {
  //   brandsifdata &&
  //     brandsifdata.sort(function(a, b) {
  //       return a.price - b.price
  //     })
  // }


  return (
    <div
      id={"pricetable"}
      style={{ paddingBottom: "20px" }}
      className={"pricetable"}
    >
      <br />

      <div id={'warningtext'}>
        WARNING: Prices are approximate and can change daily. We are not
        responsible for updating prices. Please use as a guide only
      </div>

        <div id={'pricetablegrid'}>
          <div>
            <div className={'pricetableheader'}>Under $500</div>
              {brandsifdata&&
            brandsifdata.filter(el => el.price < 500 )
            .map( (key, index) => {
              return             (  <div className={"tableitem"} key={brandsifdata[index].uniqueID}>
                  <div state={{ scooterbrand: brandsifdata[index].uniqueID }}>
                    {brandsifdata[index].brand} - {brandsifdata[index].model} - ${brandsifdata[index].price}
                  </div>
                </div>)
            })}
          </div>



        <div>
          <div className={'pricetableheader'}>Under $1000</div>

                    {brandsifdata &&
             brandsifdata.filter(el=>el.price > 500 && el.price < 1000 )
          		.map( (key, index) => {
            return             (  <div className={"tableitem"} key={key.uniqueID}>
                <div state={{ scooterbrand: key.uniqueID }}>
                  {key.brand} - {key.model} - ${key.price}
                </div>
              </div>)
          })}
        </div>

        <div>
          <div className={'pricetableheader'}>Under $1500</div>
                    {brandsifdata &&
             brandsifdata.filter(el=>el.price > 1000 && el.price < 1500 )
          		.map( (key, index) => {
            return             (  <div className={"tableitem"} key={key.uniqueID}>
                <div state={{ scooterbrand: key.uniqueID }}>
                  {key.brand} - {key.model} - ${key.price}
                </div>
              </div>)
          })}
        </div>

        <div className={'pricetableheader'}>
          <div>Over $1500</div>
                    {brandsifdata &&
             brandsifdata.filter(el=>el.price > 1500  )
          		.map( (key, index) => {
            return             (  <div className={"tableitem"} key={key.uniqueID}>
                <div state={{ scooterbrand: key.uniqueID }}>
                  {key.brand} - {key.model} - ${key.price}
                </div>
              </div>)
          })}
        </div>
</div>
      </div>
  )
}
