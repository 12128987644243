import React, { useEffect } from 'react'
import * as d3 from "d3";




const BulletsChart = props => {
	var data = {
  labels: [
    'Price','Motor Wattage', 'Weight', 'Top Speed', 'Range'
  ],
  series: [

    {
      label: 'Razor E-90',
      values: [109,90, 24, 10, 12]
    },
    {
      label: 'Hover-1 Rally',
      values: [229,300, 27, 12, 7]
    },
          {
      label: 'Razor E-300',
      values: [289,250, 53, 15, 12]
    },
    {
      label: 'Gotrax GXL V2',
      values: [298,250, 27, 15.5,11]
    },
	  {
      label: 'Swagtron 5',
      values: [349,250, 30, 18, 11]
    },
    {
      label: 'MacWheel MX1',
      values: [379,350, 30, 15.6, 18.6]
    },
          {
      label: 'Razor E-Prime',
      values: [419,0, 26.5, 15, 15]
    },
    {
      label: 'XiaomMi M365',
      values: [379,250, 26.9, 15.5,18.6]
    },
	  {
      label: 'FluidFreeRide Mosquito',
      values: [479,300, 22, 20,15]
    },
  ]
};

var chartWidth       = 500,
    barHeight        = 40,
    groupHeight      = barHeight * data.series.length,
    gapBetweenGroups = 20,
    spaceForLabels   = 250;


// Zip the series data together (first values, second values, etc.)
var zippedData = [];
for (var i=0; i<data.labels.length; i++) {
  for (var j=0; j<data.series.length; j++) {
    zippedData.push(data.series[j].values[i]);
  }
}
useEffect(
        ()=> {
            // Color scale
            const color = d3.scaleOrdinal(d3.schemeCategory10)

            var chartHeight = barHeight * zippedData.length + gapBetweenGroups * data.labels.length;

            var x = d3.scaleLinear()
                .domain([0, d3.max(zippedData)])
                .range([0, chartWidth]);

            var y = d3.scaleLinear()
                .range([chartHeight + gapBetweenGroups, 0]);

            var yAxis = d3.axisLeft()
                .scale(y)
                .tickFormat('')
                .tickSize(5)


            // Specify the chart area and dimensions
            var chart = d3.select(".chart")
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr("viewBox", "0 0 1100 2000")

			var div = d3.select("body").append("div")
				.attr("class", "tooltip")
				.style("opacity", 1);

            // Create bars
            var bar = chart.selectAll("g")
                .data(zippedData)
                .enter().append("g")
                .attr("transform", function (d, i) {
                    return "translate(" + spaceForLabels + "," + (i * barHeight + gapBetweenGroups * (0.5 + Math.floor(i / data.series.length))) + ")";
                })

      			// .on('mouseout', tip.hide);


            // Create rectangles of the correct width
            bar.append("rect")
                .attr("fill", function (d, i) {
                    return color(i % data.series.length);
                })
                .attr("class", "bar")
                .attr("width", x)
                .attr("height", barHeight - 1)
				.on("mouseover", function(d,i) {
        div
          .text(data.series[i % data.series.length].label)
          .style("left", d3.event.pageX + "px")
          .style("top", d3.event.pageY + "px")
            .style("display", "inline-block")
          .style("font-size", "20px");
      })
        .on("mouseout", function(d){ div.style("display", "none");});


            // Add text label in bar
            bar.append("text")
                .attr("x", function (d) {
                    return x(d) + 30;
                })
                .attr("y", barHeight / 2)
                .attr("fill", "red")
                .attr("dy", ".75em")
                .text(function (d) {
                    return d;
                });

            // Draw labels
            bar.append("text")
                .attr("class", "label")
                .attr("x", function (d) {
                    return -50;
                })
                .attr("y", groupHeight / 2)
                .attr("dy", ".7em")
				.style("font-size", "3vh")
                .text(function (d, i) {
                    if (i % data.series.length === 0)
                        return data.labels[Math.floor(i / data.series.length)];
                    else
                        return ""
                });

            chart.append("g")
                .attr("class", "y axis")
                .attr("transform", "translate(" + spaceForLabels + ", " + -gapBetweenGroups / 2 + ")")
                .call(yAxis);

            // Draw legend
            var legendRectSize = 18,
                legendSpacing = 4;

            var legend = chart.selectAll('.legend')
                .data(data.series)
                .enter()
                .append('g')
                .attr('transform', function (d, i) {
                    var height = legendRectSize + legendSpacing;
                    var offset = -gapBetweenGroups / 2;
                    var horz = spaceForLabels + chartWidth + 40 - legendRectSize + 140;
                    var vert = i * height - offset;
                    return 'translate(' + horz + ',' + vert + ')';
                });

            legend.append('rect')
                .attr('width', legendRectSize)
                .attr('height', legendRectSize)
                .style('fill', function (d, i) {
                    return color(i);
                })
                .style('stroke', function (d, i) {
                    return color(i);
                });

            legend.append('text')
                .attr('class', 'legend')
                .attr('x', legendRectSize + legendSpacing)
                .attr('y', legendRectSize - legendSpacing)
                .text(function (d) {
                    return d.label;
                });
        })
    return (
        <div style={{'width':'100%','height':'50%'}}>
            <h2>Scooter Comparison Chart</h2>
            <h3>Under $500 scooter metrics</h3>
            <svg className="chart"></svg>

			            <style>{`

.chart .legend {
  fill: black;
  font: 14px sans-serif;
  text-anchor: start;
  font-size: 12px;
}
.chart text {
  fill: red;
  font: 10px sans-serif;
  text-anchor: end;
}

.chart .label {
  fill: black;
  font: 14px sans-serif;
  text-anchor: end;
}

.bar:hover {
  fill: brown;
}
.tooltip {
	position: absolute;
  display: none;
  min-width: 80px;
  height: auto;
  background: none repeat scroll 0 0 #ffffff;
  border: 1px solid #6F257F;
  padding: 14px;
  text-align: center;
}
.axis path,
.axis line {
  fill: none;
  margin: 30px;
  stroke: #000;
  shape-rendering: crispEdges;
}

    `}</style>
        </div>

    )
}

export default BulletsChart
